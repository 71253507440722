<template>
  <div>
    <!-- Page Heading -->
      <v-toolbar flat>
        <pageHeading pageTitle="Incentive Top-up Details"/>
        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="500px">
          <template v-slot:activator="{ on }">
              <v-btn class="mx-2" depressed dark color="primary" v-on="on" 
                v-show="canDo && canDo.can.includes('import_topup_lists') && importable">
                <v-icon left dark>mdi-upload</v-icon> Import
              </v-btn>
          </template>
          <!-- Modal Content -->
          <v-card>
            <v-card-title>
              <span class="headline">Upload Incentive Topups</span>
            </v-card-title>

            <v-card-text>
              <v-form v-model="form_valid" ref="form">
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                       <v-select
                        :items="allIncentives"
                        label="Incentive Programs"
                        v-model="editedItem.incentive_program_id"
                        :rules="[validate_rules.required]"
                        no-data-text="Select.."
                        item-text="name"
                        item-value="id"
                        disabled
                        clearable
                      ></v-select>
                    </v-col>
                     <v-col cols="12" sm="12" md="12">
                      <v-menu
                        v-model="datePicker"
                        :close-on-content-click="false"
                        max-width="290"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            label="Incentive Date"
                            :value="incentive_date_formatted"
                            :rules="[validate_rules.required]"
                            disabled
                            readonly
                            v-on="on"
                            @click:clear="date = null"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="editedItem.incentive_date"
                          @change="datePicker = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                      <v-file-input
                          v-model="topup_file"
                          :rules="[validate_rules.required,validate_rules.filesize,validate_rules.filetype]"
                          accept="text/csv"
                          placeholder="Browse a csv file"
                          label="Topup List"
                      ></v-file-input>
                  </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
              <v-btn color="blue darken-1" text @click="save">Save</v-btn>
            </v-card-actions>
          </v-card>
          <!-- End Modal Content -->
        </v-dialog>
        <v-btn class="mx-1" depressed dark color="green darken-2" @click="sendApproveRequest()"
          v-show="canDo && canDo.can.includes('approve_request')"
          v-if="['pending','rejected'].includes(captions.status)">
          <v-icon left dark>mdi-check-bold</v-icon> Approve
        </v-btn>
        <v-btn class="mx-1" depressed dark color="red darken-4" @click="deleteThisTopups()" 
          v-show="canDo && canDo.can.includes('delete')"
          v-if="['pending','rejected'].includes(captions.status)">
          <v-icon left dark>mdi-close-circle</v-icon> Delete
        </v-btn>
        <v-btn class="mx-1 px-8" depressed dark color="grey" @click="()=>$router.push('/incentive/topup')">Back</v-btn>
      </v-toolbar> 
    <v-divider></v-divider> 
    <!-- <div class="fullwidth bottom-line"> -->
      <v-card flat>
        <v-card-text  class="captions">
          <v-row>
            <v-col cols="4" class="pl-5">
                  <h4>Program: <span>{{ captions.program }}</span></h4>
                  <h4>Date: <span>{{ captions.date | date }}</span></h4>
                  <h4>Zendesk TicketID: <span>{{ captions.zendeskID }}</span></h4>
            </v-col>
            <v-col cols="4" class="pl-5" style="position:relative;">
              <div class="bottom-stick">
                  <widgetState title="Drivers" :value="captions.drivers"/>
                  <widgetState title="Total Amount" :value="captions.topup_amount | comma"/>
                  <widgetState title="Topup Status" :value="captions.status" :border="false"/>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    <v-divider></v-divider> 
    <!-- </div> -->
    <div class="content-wrap">
        <v-toolbar class="search-tools pa-0 mb-4" flat>
          <!-- Action Button -->
          <!-- <v-spacer></v-spacer> -->
          <v-col class="d-flex pl-0" cols="4" sm="4">
            <v-text-field
              label="employee no, name..."
              v-model="options.filter.keyword"
              dense
              hide-details
              prepend-inner-icon="mdi-magnify"
              class="hidden-sm-and-down"
            /> 
          </v-col>
          <v-col class="d-flex" cols="3" sm="3">
            <v-select
              :items="TransactionStatus"
              label="Transaction Status"
              v-model="options.filter.trans_status"
              no-data-text="Select.."
              clearable
              dense
              hide-details
            ></v-select>
          </v-col>
        </v-toolbar>
        <v-data-table 
            :items="filterList" 
            :headers="headers"
            :options.sync="options"
            sort-by="id"
            :sort-desc="false"
            :itemsPerPage="30"
            no-data-text="There is no data"
        >
          <template #item.updatedAt="{value}">
              {{ value | datetime}}
          </template>
          <template #item.sms_text="{value}">
              <span style="font-size:10px">{{ value }}</span>
          </template>
          <template #item.employee_transaction.tmn_transfer_status="{value}">
              {{ value | trans_status }}
          </template>
          <template #item.employee.name_th="{item}">
              <!-- {{ value | datetime}} -->
              <router-link v-if="item.employee.name_th" :to="{ name: 'DriversDetails', params: { id: item.employee_id }}">{{ item.employee.name_th }}</router-link>
          </template>
          <template #item.action="{item}">
                <v-btn class="ma-1" outlined color="primary" icon tile small>
                  <!-- <v-icon small @click="editItem(item)">mdi-pencil</v-icon> -->
                  <v-icon small @click="()=>$router.push(`/drivers/${item.id}/edit`)">mdi-pencil</v-icon>
                </v-btn>
                <v-btn class="ma-1" outlined color="primary" icon tile small>
                  <v-icon small @click="()=>$router.push(`/drivers/${item.id}/details`)">mdi-text-box</v-icon>
                </v-btn>
          </template>
        </v-data-table>
    </div>
  </div>
</template>
<script>
import moment from 'moment';
import { mapGetters, mapActions } from 'vuex';
import pageHeading from '@/components/common/pageHeading.vue';
import widgetState from '@/components/common/widgetState.vue';
import _ from 'lodash';

export default {
  name: 'DriverListings',
  components: {
      pageHeading,
      widgetState
  },
  data: () => ({
    dialog: false,
    importable: false,
    search: '',
    options: {
        filter: {
          keyword: '',
          trans_status: null,
        }
    },
    datePicker: false,
    topup_file: null,
    form_valid : false,
    validate_rules : {
        required: v => !!v || 'This field is required',
        select2: v => {
          if(typeof v === 'object'){
            return (v.value !== '') || 'This field is required';
          } else {
            return true
          }
        },
        filesize:  v => !v || v.size < 2000000 || 'Updload size should be less than 2 MB!',
        filetype:  v => {
          if(v){
            var ext = v.name.split('.').pop();
            return ["csv"].includes(ext) || 'Please upload .csv file only';
          } else {
            return 'This field is required'
          }
        }
    },
    transaction_status: null
  }),
  methods: {
    ...mapActions(['genCan','getIncentives','getIncentiveTopupsDetail','deleteIncentiveTopups','sendRequestIncentiveTopups','createIncentiveTopups']),
    sendApproveRequest () {
        confirm('Are you sure to send an Approve Request?') && this.sendRequestIncentiveTopups(this.$route.params.id);
    },
    deleteThisTopups () {
        confirm('Are you sure you want to delete this topup?') && this.deleteIncentiveTopups(this.$route.params.id);
    },
    close () {
      this.dialog = false
      setTimeout(() => {
        // this.editedItem = Object.assign({}, this.defaultItem)
        // this.editedIndex = -1
        this.$refs.form.resetValidation() // prevent validate error show again when click add
      }, 300)
    },
    save () {
      if(this.form_valid){
        this.createIncentiveTopups(this.editedItem);
        this.close();
        this.topup_file = null;
      }
    },
  },
  computed: {
    ...mapGetters(['canDo','IncentiveTopupsDetail','TransactionStatus','allIncentives']),
    captions(){
      return {
        program: this.IncentiveTopupsDetail.incentive_program ? this.IncentiveTopupsDetail.incentive_program.name : '',
        date: this.IncentiveTopupsDetail.incentive_date,
        zendeskID: this.IncentiveTopupsDetail.zendesk_ticket_id,
        drivers: this.IncentiveTopupsDetail.employee_manual_topup ? this.IncentiveTopupsDetail.employee_manual_topup.length : 0,
        topup_amount: this.IncentiveTopupsDetail.employee_manual_topup ? this.totalTopupsAmount : 0,
        status: this.IncentiveTopupsDetail.status,
      }
    },
    editedItem() {
      return {
          id: this.IncentiveTopupsDetail ? this.IncentiveTopupsDetail.id : -1,
          incentive_program_id: this.IncentiveTopupsDetail ? this.IncentiveTopupsDetail.incentive_program_id : '',
          incentive_date: this.IncentiveTopupsDetail ? this.IncentiveTopupsDetail.incentive_date : '',
          topup_file: this.topup_file,
          replace: true
      }
    },
    totalTopupsAmount(){
       var total = 0;
        this.IncentiveTopupsDetail.employee_manual_topup.forEach( function(item) {
            total += item.amount;
        });
       return total;
    },
    incentive_date_formatted () {
        return this.editedItem.incentive_date ? moment(this.editedItem.incentive_date).format('DD-MM-YYYY') : '';
    },
    headers(){
      return [
        {text: "id", value: 'id'},
        {text: "Employee No.", value: 'employee.employee_no'},
        {text: "Name", value: 'employee.name_th'},
        {text: "Topup Amount", value: 'amount'},
        {text: "SMS Message", value: 'sms_text', sortable: false, width: '300px'},
        {text: "Transaction id", value: 'employee_transaction.tmn_transaction_id',align: 'center', width: '120px'},
        {text: "Transaction Status", value: 'employee_transaction.tmn_transfer_status',align: 'center', width: '120px'},
        // {text: "Action", value: 'action', sortable: false, align: 'center', width: '110px'}, 
      ]
    },
    filterList() { 
      var filter = this.options.filter;
      return _.filter(this.IncentiveTopupsDetail.employee_manual_topup, function(query){
        var keyword = filter.keyword ? query.employee.employee_no.includes(filter.keyword) || query.employee.name_th.includes(filter.keyword) : true,
            status = filter.trans_status ? ( query.employee_transaction ?  query.employee_transaction.tmn_transaction_status == filter.trans_status : false) : true;
        return  keyword && status;
      })
    }
  },
  watch: {
    'IncentiveTopupsDetail.status': function (val){
         //to work with changes in someOtherProp
        if(val){
          if(['pending','rejected'].includes(val)){
            this.importable = true;
          } else {
            this.importable = false;
          }
        } else {
          this.importable = false;
        }
     },
    'TransactionStatus': function (val){
         //to work with changes in someOtherProp
        if(val){
          this.transaction_status = val;
        }
     },
    dialog (val) {
      val || this.close()
    },
  },
  filters:{
    trans_status(value){
      const trans = [
          { value: '0', text : 'Pending'},
          { value: '1', text : 'Success'},
          { value: '-1', text : 'Verify Fail'},
          { value: '-2', text : 'Notify Fail'},
      ];
      var d = trans.filter(item => item.value == value);
      return  value ? d[0].text : '';
    }
  },
  created() {
    this.genCan();
    this.getIncentives();
    this.getIncentiveTopupsDetail(this.$route.params.id);
  }
};
</script>
<style scoped>
.v-data-table {
  max-width: 100%
}
.captions h4 span {
    font-weight: normal !important;
}
.bottom-stick{
  position:absolute;
  bottom: 10px;
}
</style>