<template>
    <v-toolbar-title>
        <span class="headline pa-2 mb-2 pageTitle">{{ pageTitle }}</span>
        <span class="pa-2 mb-2 detail-id" v-if="detailID">[ No. {{ detailID }} ]</span>
    </v-toolbar-title>
</template>
<script>
export default {
    name: 'pageHeading',
    props: ['pageTitle','detailID'],
}
</script>
<style scoped>
.pageTitle{
    width:300px;
}
.detail-id {
    color:#666;
    font-size: 16px
}
</style>