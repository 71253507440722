<template>
    <div class="widget-container" :class="{ 'has-border': border }">
        <span class="widget-value">{{ value }}</span>
        <span class="widget-title">{{ title }}</span>
    </div>
</template>
<script>
export default {
    name: 'widgetState',
    props: {
        title: {
            type: String,
            default: '',
            required: true
        },
        value: {
            type: [ String, Number ],
            default: '-',
            required: true
        },
        border: {
            type: Boolean,
            default: true
        }
    },
}
</script>
<style scoped>

.widget-container{
    display: inline-block;
    padding-right: 10px;
    margin-right: 10px;
}
.widget-title, .widget-value{
    display: block;
    align:left;
}
.widget-title{
    font-size: 9px;
    line-height: 9px;
    color:#666666;
    margin-top: 3px;
}
.widget-value{
    font-size: 17px;
    color:#1E1E1E;
}
.has-border {
    border-right: 1px solid #ddd;
}

</style>